export const theme = {
  maxContainerWidth: '1080px',
  darkGray: '#4d4d4d',
  defaultFont: '"Roboto", sans-serif',
  defaultHeaderFont: '"Palanquin", sans-serif',
  defaultFontColor: '#383735',
  defaultFontSize: '16px',
  defaultParagraphLineHeight: '1.5',
  defaultHeaderColor: '#000000',
  defaultInputBackgroundColor: '#f5f5f5',
  defaultMobileFontSize: '16px',
  defaultTransition: 'all 0.2s linear',
  desktopHeaderHeight: '75px',
  disabledColor: '#888888',
  errorColor: '#e44100',
  focusColor: '#0080EE',
  grayText: '#707070',
  lightGray: '#B3B3B3',
  linkColor: '#0080EE',
  mobileHeaderHeight: '60px',
  primaryColor: '#0080EE',
  secondaryColor: '#f57e58',
  hoveredSecondaryColor: '#fc9a7c',
  pressedSecondaryColor: '#fc9a7c',
  lightBackgroundColor: '#EDF6FE',
  borderBlurColor: '#CFE7EE',
  white: '#ffffff',
  bodyBackgroundColor: '#ffffff !important',
}
